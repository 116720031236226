.banner_image {
  display: block;
  width: 100%;
}

.banner_image img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
