.hamburger {
  display: none;
}

@media screen and (max-width: 640px) {
  .hamburger {
    position: absolute;
    z-index: 1;
    top: 22px;
    right: calc(100% / 6 - 24px);
    display: inline-block;
    color: #000;
    width: 33px;
    height: 22px;
  }
}
