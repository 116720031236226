.header {
  background-color: #fff;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  height: 65px;
  top: 0px;
  left: 0px;
  z-index: 10;
}

.home .header {
  height: 140px;
}

.logo {
  height: 50px;
  margin: 25px 0 0;
  vertical-align: top;
}

@media screen and (max-width: 640px) {
  .home .header {
    height: 65px;
  }

  .logo {
    display: none;
  }
}
