.multi-image-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  column-gap: 5px;
  width: 100%;
}

.multi-image-container img {
  height: 100%;
}
